import styled from 'styled-components';

import { Container } from 'components/common';

import useScreen from 'utils/useScreen';

import offer from 'assets/images/offer.png';

import {finder} from "../../utils/finder";

const Root = styled.section(({ isMobile }) => `
    width: 100%;
    height: ${isMobile ? 500 : 940}px;
    overflow: hidden;
    background-size: cover;
    background-position: bottom;
    background-image: url(${offer});
    display: flex;
    align-items: flex-end;
    padding-bottom: ${isMobile ? 100 : 265 }px;
`);

const Title = styled.h3`
    font-weight: 700;
    font-size: 38px;
    line-height: 46px;
    text-transform: uppercase;
    margin-bottom: 70px;
`;

const GRID_SPACING = '60px';

const GridContainer = styled.div(({ isMobile }) => isMobile
    ? `
        width: 100%;
        overflow: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
    ` : `
        display: flex;
        flex-flow: row wrap;
        margin-top: -${GRID_SPACING};
        margin-left: -${GRID_SPACING};
        width: calc(100% + ${GRID_SPACING});
    `
);

const GridItem = styled.div(({ isMobile }) => `
    ${!isMobile
        ? `
            flex-grow: 0;
            max-width: 33.3333%;
            flex-basis: 33.3333%;
        ` : `
            min-width: 240px;
            margin-right: 20px;
        `
    }
    padding-top: ${isMobile ? 0 : GRID_SPACING};
    padding-left: ${isMobile ? 0 : GRID_SPACING};
`);

const GridImage = styled.img`
   width: 50px;
`;

const GridTitle = styled.p`
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-transform: uppercase;
`;

const GridSubtitle = styled.p`
    font-size: 17px;
    font-weight: 400;
    max-width: 328px;
    line-height: 21px;
`;


const OfferSection = ({ statics, data }) => {
    const { isMobile } = useScreen();

    return (
        <Root id="services" isMobile={isMobile}>
            <Container>
                <Title>
                    {finder(statics, 'key', 'serviceTitle', 'value')}
                </Title>
                <GridContainer isMobile={isMobile}>
                    {data?.map(({ attributes }) => (
                      <GridItem isMobile={isMobile}>
                          <GridImage src={`https://li1420-225.members.linode.com${attributes?.icon?.data?.attributes?.url}`} />
                          <GridTitle>
                              {attributes.title}
                          </GridTitle>
                          <GridSubtitle>
                              {attributes.description}
                          </GridSubtitle>
                      </GridItem>
                    ))}
                </GridContainer>
            </Container>
        </Root>
    );
}

export default OfferSection;