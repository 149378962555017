import styled from 'styled-components';

import searchIcon from 'assets/images/Search.svg';

const Root = styled.div`
    height: 64px;
    background: rgba(241, 241, 241, 0.5);
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 90.5px;
    overflow: hidden;
    position: relative;
`;

const Field = styled.input`
    width: calc(100% - 90px);
    height: 100%;
    background: transparent;
    border: none;
    outline: none;
    padding: 0 50px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
`;

const Icon = styled.em`
    width: 38px;
    height: 38px;
    background-size: cover;
    position: absolute;
    top: 11px;
    right: 50px;
    background-image: url(${searchIcon});
`;

const SearchField = (props) => {
    return (
        <Root>
            <Field {...props} placeholder="Search..." />
            <Icon />
        </Root>
    );
}

export default SearchField;