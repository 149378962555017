import WelcomeSection from 'components/sections/Welcome';

import { useLoading } from "../../useLoader";

import OfferSection from './Offer';
import AboutUsSection from './AboutUs';
import OurClients from './OurClients';
import Header from "../../components/sections/Header";
import Footer from "../../components/sections/Footer";

const HomePage = () => {
    const { contacts, clients, counts, about, statics, services } = useLoading();

    return (
        <>
          <Header />
          <WelcomeSection statics={statics?.data}/>
          <OfferSection data={services?.data} statics={statics?.data} />
          <AboutUsSection
            statics={statics?.data}
            data={counts?.data}
            about={about?.data}
          />
          <OurClients data={clients?.data}/>
          <Footer data={contacts?.data}/>
        </>
    )
}

export default HomePage;