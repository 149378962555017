import styled from 'styled-components';

import { Container } from 'components/common';

const OutClientsContainer = styled(Container)`
    text-align: center;
    padding: 40px 0 80px 0;
`;

const Title = styled.h3`
    font-weight: 700;
    font-size: 38px;
    line-height: 46px;
    text-transform: uppercase;
`;

const ClientLogo = styled.div`
    display: inline-block;
    width: 170px;
    height: 100px;
    margin: 15px;
    background-image: url("${({ src }) => src}");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    filter: grayscale(100%);
  
    :hover {
      filter: grayscale(0%);
    }
`;

const OurClientsSection = ({ data }) => {
    return (
        <OutClientsContainer>
            <Title>
                OUR CLIENTS:
            </Title>
            <div style={{ paddingTop: 40 }}>
                {data?.map(({ attributes }) => (
                  <ClientLogo src={`https://li1420-225.members.linode.com${attributes.image.data.attributes?.url}`} />
                ))}
            </div>
        </OutClientsContainer>
    );
}

export default OurClientsSection;