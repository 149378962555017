import styled from 'styled-components';

import { Container } from 'components/common';

import aboutus from 'assets/images/aboutus.png';
import hexagon1 from 'assets/images/hexagon1.svg';
import hexagon2 from 'assets/images/hexagon2.svg';
import hexagon3 from 'assets/images/hexagon3.svg';
import aboutusrectangle from 'assets/images/aboutus-rectangle.png';

import useScreen from "../../utils/useScreen";
import { finder } from "../../utils/finder";

const hexagons = [
  {
    image: hexagon1,
    size: isMobile => isMobile ? 300 : 400,
  },
  {
    image: hexagon2,
    size: isMobile => isMobile ? 220 : 320,
  },
  {
    image: hexagon3,
    size: isMobile => isMobile ? 340 : 440,
  }
];

const Root = styled.section`
    width: 100%;
    height: ${({ isMobile }) => isMobile ? 'auto' : '2186px'};
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    background-image: url(${aboutus});
    z-index: -1;
    margin-top: -400px;
    position: relative;
    &:before {
        left: 0;
        content: "";
        top: -400px;
        width: 100%;
        height: 600px;
        background: black;
        position: absolute;
    }
`;

const InfoRectangle = styled.div`
    margin-top: 100px;
    margin-left: -130px;
    padding-top: 350px;
    padding-left: 130px;
    padding-right: 130px;
    width: 1000px;
    height: 800px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${aboutusrectangle});
    & > h4 {
        font-weight: 700;
        font-size: 38px;
        line-height: 46px;
        text-transform: uppercase;
    }
    & > p {
        font-weight: 400;
        font-size: ${({ isMobile }) => isMobile ? '17px' : '25px'};
        width: ${({ isMobile }) => isMobile ? '300px' : 'auto'};
        line-height: 30px;
    }
`

const SuggestionsBox = styled.div`
    width: 372px;
    margin-right: 130px;
    padding-bottom: 60px;
    display: inline-block;
    color: ${({ color }) => color || 'white'};
    & > h5 {
        font-weight: 700;
        font-size: 38px;
        line-height: 46px;
        margin-bottom: 70px;
        text-transform: uppercase;
    }
    & > p {
        font-weight: 400;
        font-size: 17px;
        width: ${({ isMobile }) => isMobile ? '300px' : 'auto'};
        line-height: 21px;
    }
`

const HexagonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 -80px;
    flex-direction: ${({ isMobile }) => isMobile ? 'column' : 'row'};
`;

const Hexagon = styled.div`
    width: ${({ size }) => size || 400}px;
    height: ${({ size }) => size || 400}px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: ${({ bgImage }) => `url(${bgImage})`};
    & > b {
        font-weight: 700;
        font-size: 128px;
        line-height: 156px;
    }
    & > span {
        font-weight: 700;
        font-size: 38px;
        line-height: 46px;
    }
`;

const AboutUsSection = ({ data, about, statics }) => {
    const { isMobile } = useScreen();

    return (
        <Root isMobile={isMobile}>
            <Container>
                <InfoRectangle id="about" isMobile={isMobile}>
                    <h4>
                        {finder(statics, 'key', 'aboutTitle', 'value')}
                    </h4>
                    <p>
                        {finder(statics, 'key', 'aboutDescription', 'value')}
                    </p>
                </InfoRectangle>
                {about?.map(({ attributes }) => (
                  <SuggestionsBox isMobile={isMobile}>
                      <h5>
                          {attributes.title}
                      </h5>
                      <p>
                          {attributes.description}
                      </p>
                  </SuggestionsBox>
                ))}
                <SuggestionsBox color="#FFCD23" isMobile={isMobile}>
                    <h5>
                        {finder(statics, 'key', 'countTitle', 'value')}
                    </h5>
                    <p>
                        {finder(statics, 'key', 'countDescription', 'value')}
                    </p>
                </SuggestionsBox>
                <HexagonContainer isMobile={isMobile}>
                    {data?.map(({ attributes }, index) => (
                      <Hexagon bgImage={hexagons[index].image} size={hexagons[index].size(isMobile)}>
                          <b>{attributes.value}</b>
                          <span>{attributes.title}</span>
                      </Hexagon>
                    ))}
                </HexagonContainer>
            </Container>
        </Root>
    );
}

export default AboutUsSection;