import {useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import scrollToComponent from 'react-scroll-to-component';

import { Container } from 'components/common';
import Accordion from 'components/common/Accordion';
import SearchField from 'components/common/SearchField'
import WelcomeSection from 'components/sections/Welcome';
import Header from "../../components/sections/Header";
import Footer from "../../components/sections/Footer";
import {useLoading} from "../../useLoader";

const Root = styled.section`
    padding: 60px 0;
`;

const data = Array(20).fill(
    {
        title: 'Strong Mid-level Node.js',
        description: `
            <b>Requirements</b><br /><br />
            3 years of Node.js experience is preferred Express<br />
            MongoDB <br />
            Mongoose <br />
            Redis <br />
            RabbitMQ <br />
            Rest API <br />
            Knowledge of another Node.js framework is preferred <br /><br />
            <b>Responsibilities</b> <br /><br />
            Writing reusable, testable, and efficient code <br />
            Design, implementation, and maintenance of low- latency, highly available, scalable, and performant services <br />
            Implementation of security and data protection <br />
            Compile and analyze data, processes, and codes to troubleshoot problems and identify areas for improvement <br />
            Collaborate with the Front-end engineers, QA specialists, and other team members to establish objectives and design more functional, cohesive codes to enhance the user experience <br />
            Participating in continuing education and training, learning new programming techniques, adopting new technologies <br />
            Database creation, integration, and management
        ` 
    }
);

const JobsPage = () => {
    const { hash } = useLocation();

    const { statics, contacts, jobs } = useLoading();

    const [searchValue, setSearchValue] = useState('');

    const handleChange = (e) => {
        setSearchValue(e.target.value);
    }

    const scrollToElement = () => {
      const elem = document.getElementById(hash.replace('#', ''));
      scrollToComponent(elem, {
        align: 'top',
        duration: 1000
      });
    }

    const filteredJobs = useMemo(() => {
      return jobs?.data?.filter(({ attributes }) => {
        return attributes.title.toLowerCase().search(searchValue.toLowerCase()) > -1;
      }) || [];
    }, [jobs, searchValue]);

    useEffect(() => {
      hash && scrollToElement();
    }, [hash]);

    return (
        <>
          <Header />
            <WelcomeSection statics={statics?.data}/>
            <Root id="list">
              <Container>
                  <SearchField
                      value={searchValue}
                      onChange={handleChange}
                  />
                  {filteredJobs.map((item, index) => (
                      <Accordion key={index} id={item.id} {...item.attributes} />
                  ))}
              </Container>
            </Root>
          <Footer data={contacts?.data}/>
        </>
    )
}

export default JobsPage;