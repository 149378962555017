import { useState, useContext, createContext, useMemo, useEffect } from 'react';
import { fetchAbout, fetchClients, fetchContacts, fetchCounts, fetchServices, fetchStatics, fetchJobs } from "./api";

const LoadingContext = createContext(false);

export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const [contacts, setContacts] = useState({});
  const [clients, setClients] = useState({});
  const [about, setAbout] = useState({});
  const [services, setServices] = useState({});
  const [statics, setStatics] = useState({});
  const [counts, setCounts] = useState({});
  const [jobs, setJobs] = useState({});

  const value = useMemo(() => {
    return {
      loading,
      contacts,
      clients,
      about,
      services,
      statics,
      counts,
      jobs,
    };
  }, [
    loading,
    contacts,
    clients,
    about,
    services,
    statics,
    counts,
    jobs,
  ]);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      fetchContacts(),
      fetchClients(),
      fetchAbout(),
      fetchServices(),
      fetchStatics(),
      fetchCounts(),
      fetchJobs(),
    ]).then(([con, cli, abo, serv, sta,cou, jo]) => {
      setContacts(con);
      setClients(cli);
      setAbout(abo);
      setServices(serv);
      setStatics(sta);
      setCounts(cou);
      setJobs(jo);
      setLoading(false);
    });
  }, []);

  return (
    <LoadingContext.Provider value={value}>
      {children}
    </LoadingContext.Provider>
  )
}

export const useLoading = () => {
  return useContext(LoadingContext);
}