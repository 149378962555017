import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Container } from 'components/common';

import useScreen from 'utils/useScreen';

import welcome from 'assets/images/welcome.png';
import welcomeButton from 'assets/images/welcomeButton.png';
import {finder} from "../../../utils/finder";

const Root = styled.section(({ isMobile }) => `
    width: 100%;
    height: ${isMobile ? 750 : 1195}px;
    overflow: hidden;
    background-size: ${isMobile ? ' 175%' : 'cover'};
    background-position: ${isMobile ? '65% bottom' : 'center'};
    background-image: url(${welcome});
    background-repeat: no-repeat;
    background-color: #feca00;
    border-radius: 0 0 ${isMobile ? '80px 150px' : '209px 387px'};
    display: flex;
    align-items: flex-end;
    padding-bottom: ${isMobile ? 220 : 277}px;
`);

const Title = styled.h3(({ isMobile }) => `
    word-spacing: 100vw; 
    font-weight: 700;
    text-align: justify;
    text-transform: uppercase;
    font-size: ${isMobile ? 33 : 60}px;
    line-height: ${isMobile ? 40 : 73}px;
`);

const Description = styled.p`
    width: 300px;
    font-weight: 400;
    font-size: 17px;
    line-height: 21px;
    text-align: justify;
`;

const Button = styled.button(({ isMobile }) => `
    width: 344px;
    height: 75px;
    border: none;
    margin-top: 37px;
    background-size: cover;
    background-position: center;
    background-color: transparent;
    background-image: url(${welcomeButton});
    color: #fff;
    font-weight: 700;
    font-size: 17px;
    line-height: 21px;
    text-transform: uppercase;
    zoom: ${isMobile ? 70 : 100}%;
`);

const WelcomeSection = ({ statics }) => {
    const navigate = useNavigate();
    const { isMobile } = useScreen();

    return (
        <Root isMobile={isMobile}>
            <Container>
                <Title isMobile={isMobile}>
                    {finder(statics, 'key', 'mainTitle', 'value')}
                </Title>
                <Description>
                    {finder(statics, 'key', 'mainDescription', 'value')}
                </Description>
                <Button
                  isMobile={isMobile}
                  onClick={() => navigate('openings#list')}
                >
                    Career opportunities
                </Button>
            </Container>
        </Root>
    );
}

export default WelcomeSection;