import styled from 'styled-components';

import { Container } from 'components/common';

import footer from 'assets/images/footer.png';
import footerMap from 'assets/images/map2.png';
import facebookIcon from 'assets/images/Facebook.svg';
import linkedInIcon from 'assets/images/LinkedIn.svg';
import useScreen from "../../../utils/useScreen";

const Root = styled.footer`
    width: 100%;
    min-height: 700px;
    color: white;
    padding-top: 130px;
    background-size: cover;
    background-position: top center;
    background-image: url(${footer});
`;

const FooterContainer = styled(Container)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: ${({ isMobile }) => isMobile ? 'column' : 'row'};
    & h4 {
        font-weight: 700;
        font-size: 38px;
        line-height: 46px;
        text-transform: uppercase;
    }
    & ul {
        padding: 0;
        list-style-type: none;
        & li {
            margin-bottom: 24px;
            & b {
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;
                text-transform: uppercase;
                display: block;
            }
            & span {
                font-weight: 400;
                font-size: 17px;
                line-height: 21px;
            }
        }
    }
`;

const Map = styled.div`
    width: 880px;
    height: 460px;
    background-size: cover;
    background-position: center;
    filter: grayscale(100%);
    border-top-left-radius: 90px;
    border-top-right-radius: 70px;
    border-bottom-left-radius: 190px;
    border-bottom-right-radius: 80px;
    background-image: url(${footerMap});
`;

const SubContent = styled(Container)`
    padding: 90px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const SocIcon = styled.button`
    width: 40px;
    height: 40px;
    border: none;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-color: transparent;
    background-image: url(${({ img }) => img});
    margin-left: 20px;
`;

const Footer = ({ data }) => {
    const { isMobile } = useScreen();
    return (
      <Root>
          <FooterContainer isMobile={isMobile}>
              <div id="contacts">
                  <h4>
                      Contacts
                  </h4>
                  <ul>
                    {data?.map(({ attributes }) => (
                      <li>
                        <b>{attributes.title}</b>
                        <span>{attributes.value}</span>
                      </li>
                    ))}
                  </ul>
              </div>
              <Map />
          </FooterContainer>
          <SubContent>
            <span>
                ©BumBleBee LLC 2022
            </span>
              <div>
                  <a
                    href='https://www.facebook.com/bbb.bumblebee'
                    target='_blank'
                  >
                      <SocIcon img={facebookIcon}/>
                  </a>
                  <a
                    href='https://www.linkedin.com/company/bumblebee-llc/'
                    target='_blank'
                  >
                      <SocIcon img={linkedInIcon} />
                  </a>
              </div>
          </SubContent>
      </Root>
    )
};

export default Footer;