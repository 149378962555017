import { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import useScreen from '../../../utils/useScreen';

import welcomeButton from 'assets/images/welcomeButton.png';


const Root = styled.div`
    width: 100%;
    position: relative;
    border-bottom: 1px solid #C4C4C4;
`;

const Title = styled.h4`
    margin: 24px 0;
    font-weight: 500;
    font-size: 25px;
    line-height: 46px;
    cursor: pointer;
    text-transform: uppercase;
`;

const SubTitle = styled.h5`
    display: ${({isOpen}) => isOpen ? 'block' : 'none'};
    margin: 24px 0px 24px 24px;
    font-weight: 500;
    font-size: 20px;
    line-height: 46px;
    cursor: pointer;
    text-transform: uppercase;
`;

const Description = styled.div`
    font-size: 20px;
    overflow: hidden;
    line-height: 24px;
    transition: all 0.3s ease-in-out;
    height: ${({ isOpen }) => isOpen ? 'auto' : 0};
    padding-bottom: ${({ isOpen }) => isOpen ? '24px' : 0};
`;

const ArrowIcon = styled.em`
    width: 0; 
    height: 0; 
    right: 24px;
    bottom: 24px;
    cursor: pointer;
    position: absolute;
    border-top: 10px solid #C4C4C4;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    transform: rotate(${({ isOpen }) => isOpen ? 180 : 0}deg);
`;

const ReadMore = styled.button(({ isMobile, isOpen }) => `
    display: ${isOpen ? 'block' : 'none'};
    height: 45px;
    border: none;
    margin-top: 37px;
    background-size: cover;
    background-position: center;
    background-color: transparent;
    background-image: url(${welcomeButton});
    color: #fff;
    font-weight: 700;
    font-size: 17px;
    line-height: 21px;
    text-transform: uppercase;
    zoom: ${isMobile ? 70 : 100}%;
`);

const Accordion = ({
  id,
  title,
  requirements,
  responsibilities,
  readMore = true,
  needWouldBePlus,
  needEmail,
  defaultOpened
}) => {
    const { isMobile } = useScreen();
    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(defaultOpened || false);

    const handleToggle = () => {
        setIsOpen(prev => !prev)
    }

    return (
        <Root>
            <Title onClick={handleToggle}>{title}</Title>
             <a style={{color: '#000'}} href={`mailto:${needEmail}`}>{needEmail}</a>
            {requirements?.length > 0 && <SubTitle isOpen={isOpen}>Requirements</SubTitle>}
            <Description isOpen={isOpen}>
                <ul>
                    {requirements?.map(item => (
                      <li key={item.key}>{item.value}</li>
                    ))}
                </ul>
            </Description>
            {responsibilities?.length > 0 && <SubTitle isOpen={isOpen}>Responsibilities</SubTitle>}
            <Description isOpen={isOpen}>
              <ul>
                {responsibilities?.map(item => (
                  <li key={item.key}>{item.value}</li>
                ))}
              </ul>
            </Description>
            {needWouldBePlus?.length > 0 && <SubTitle isOpen={isOpen}>Would Be Plus</SubTitle>}
            <Description isOpen={isOpen}>
              <ul>
                {needWouldBePlus?.map(item => (
                  <li key={item.key}>{item.value}</li>
                ))}
              </ul>
            </Description>
            {readMore && <ReadMore
              isOpen={isOpen}
              isMobile={isMobile}
              onClick={() => navigate(`/openings/${id}`)}
            >
              Read More
            </ReadMore>}
            {readMore && <ArrowIcon isOpen={isOpen} onClick={handleToggle} />}
        </Root>
    );
}

export default Accordion;