import { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import scrollToComponent from 'react-scroll-to-component';

import { Container } from 'components/common';

import useScreen from 'utils/useScreen';

import logo from 'assets/images/logo.svg';

const Root = styled.header`
    width: 100%;
    z-index: 1000;
    position: fixed;
    transition: 0.3s;
    padding-bottom: 30px;
    padding-top: ${({ isScrolled, isMobile }) => isMobile ? 30 : isScrolled ? 30 : 80}px;
    color: ${({ isScrolled }) => isScrolled ? 'white' : 'black'};
    background: ${({ isScrolled }) => isScrolled ? '#ffb60185' : 'transparent'}
`;

const HeaderContainer = styled(Container)`
    display: flex;
    justify-content: space-between;
    align-items: ${({ isScrolled }) => isScrolled ? 'center' : 'flex-start'};
`;

const Logo = styled.img`
    transition: 0.3s;
    width: ${({ isScrolled, isMobile }) => isMobile ? (isScrolled ? 40 : 80) : (isScrolled ? 80 : 150)}px;
`;

const Nav = styled.nav`
    a {
        color: inherit;
        text-decoration: none;
        margin-left: 60px;
        font-weight: 700;
    }
`;

const Header = () => {
    const { isMobile } = useScreen();
    const navigate = useNavigate();
    const { pathname, hash } = useLocation();

    const [isScrolled, setIsScrolled] = useState(false)

    const listenScrollEvent = () => {
        if (window.scrollY < 73) {
            setIsScrolled(false);
        } else if (window.scrollY > 70) {
            setIsScrolled(true)
        } 
    }

    const scrollToElement = (id, redirect) => {
        if(!redirect && pathname !== '/') {
            navigate('/#'+id);
            return;
        }
        const elem = document.getElementById(id);
        scrollToComponent(elem, {
            align: 'top',
            duration: 1000
        });
    }

    useEffect(() => {
        hash && scrollToElement(hash.replace('#', ''), true);
    }, [hash]);

    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent);

        return () => {
            window.removeEventListener('scroll', listenScrollEvent);
        }
    }, []);

    return (
        <Root isScrolled={isScrolled} isMobile={isMobile}>
            <HeaderContainer isScrolled={isScrolled}>
                <Link to="/">
                    <Logo isMobile={isMobile} isScrolled={isScrolled} src={logo} />
                </Link>
                {!isMobile &&
                    <Nav>
                        <a
                          style={{ cursor: 'pointer' }}
                          onClick={() => scrollToElement('services')}>
                            Services
                        </a>
                        <a
                          style={{ cursor: 'pointer' }}
                          onClick={() => scrollToElement('about')}
                        >
                            About
                        </a>
                        <a
                          style={{ cursor: 'pointer' }}
                          onClick={() => scrollToElement('contacts')}
                        >
                            Contacts
                        </a>
                        <a href="/openings#list" onClick={() => navigate('/jobs')}>Openings</a>
                    </Nav>
                }
            </HeaderContainer>
        </Root>
    )
}

export default Header;