export async function fetchServices() {
  return fetch('https://li1420-225.members.linode.com/api/services?populate=*').then(res => res.json());
}

export async function fetchAbout() {
  return fetch('https://li1420-225.members.linode.com/api/abouts?populate=*').then(res => res.json());
}

export async function fetchCounts() {
  return fetch('https://li1420-225.members.linode.com/api/counts').then(res => res.json());
}

export async function fetchContacts() {
  return fetch('https://li1420-225.members.linode.com/api/contacts').then(res => res.json());
}

export async function fetchStatics() {
  return fetch('https://li1420-225.members.linode.com/api/statics?populate=*').then(res => res.json());
}

export async function fetchClients() {
  return fetch('https://li1420-225.members.linode.com/api/clients?populate=*').then(res => res.json());
}

// JOBS part

export async function fetchJobs() {
  return fetch('https://li1420-225.members.linode.com/api/jobs?populate=*&pagination[page]=1&pagination[pageSize]=1000').then(res => res.json());
}

export async function fetchJob(id) {
  return fetch(`https://li1420-225.members.linode.com/api/jobs/${id}?populate=*`).then(res => res.json());
}
