import { useLayoutEffect } from 'react';

import {
    useLocation,
    BrowserRouter as Router,
    Routes,
    Route,
  } from "react-router-dom";

import PacmanLoader from "react-spinners/PacmanLoader";

import { useLoading } from "./useLoader";

import HomePage from 'pages/Home';
import JobsPage from 'pages/Jobs';
import JobPage from 'pages/Job';

import 'react-perfect-scrollbar/dist/css/styles.css';

const Pages = () => {
    const location = useLocation();
    
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);

  return (
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/openings" element={<JobsPage />} />
            <Route path="/openings/:id" element={<JobPage />} />
            <Route path="*" element={<HomePage />} />
        </Routes>
    )
}

const App = () => {
  const { loading } = useLoading();

  if(loading) {
    return (
      <div
        style={{
          backgroundColor: '#000',
          display: 'flex',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <PacmanLoader color='#FFC80C'/>
      </div>
    );
  }
  return (
    <Router>
      <Pages />
    </Router>
  )
}

export default App;